<template>
    <div class="cursor-pointer h-full w-full" @click="addMedia">
        <img class="min-h-full" :src="image.urls.small" />
    </div>
</template>

<script>
import { Media } from 'delivery-boosting-2021-model'

export default {
    data () {
        return {
            media: {}
        }
    },
    props: {
        image: {
            Type: Object
        },
        fullResolution: {
            Type: Boolean
        }
    },
    methods: {
        addMedia () {
            if (this.fullResolution) {
                this.media = {
                    author: this.image.user.name,
                    authorUrl: this.image.user.links.html,
                    origin: 'Unsplash',
                    url: this.image.urls.full,
                    uuid: this.image.id
                }
            } else {
                this.media = {
                    author: this.image.user.name,
                    authorUrl: this.image.user.links.html,
                    origin: 'Unsplash',
                    url: this.image.urls.regular,
                    uuid: this.image.id
                }
            }
            this.$emit('input', new Media(this.media))
        }
    }
}

</script>
