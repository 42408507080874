<template lang="html">
    <div class="tabs">
        <div class="border-b border-gray-200">
            <nav class="-mb-px flex space-x-8" aria-label="Tabs">
                <a class="cursor-pointer border-transparent whitespace-nowrap py-4 px-1 font-bold text-sm" v-for='(tab, index) in tabs' :key='tab.title' @click='selectTab(index)' :class='{"tab__selected": (index == selectedIndex)}'>
                    {{ tab.title }}
                </a>
            </nav>
        </div>
        <slot/>
    </div>
</template>

<script>
export default {
    props: {
        mode: {
            type: String,
            default: 'light'
        }
    },
    data () {
        return {
            selectedIndex: 0,
            tabs: []
        }
    },
    created () {
        this.tabs = this.$children
    },
    mounted () {
        this.selectTab(0)
    },
    methods: {
        selectTab (i) {
            this.selectedIndex = i
            this.tabs.forEach((tab, index) => {
                tab.isActive = (index === i)
            })
        }
    }
}
</script>
