<template>
  <!-- right drawer -->
  <div :class="{'hidden': !drawerVisible && modal}" @keydown.esc="hideDrawer" tabindex="0">
    <div :class="[drawerVisible ? '-translate-x-0' : 'translate-x-full', modal ? 'w-2/3 webheight rounded-r-lg' : 'w-1/3 rounded-r-none']"
        class="fixed top-0 right-0 overflow-hidden h-screen pl-8 py-8 rounded-lg z-20 bg-white border border-solid border-whitesmoke transform transition-transform">
      <div class="grid grid-cols-2 justify-between items-center mb-6 pr-8">
        <h5>{{$t('dashboard.chose_image')}}</h5>
        <div class="justify-self-end">
          <button @click="addAndSave" class="bg-primary text-white rounded text-xs py-3 pl-2 pr-4 w-28 inline-block align-middle"><icon-plus class="w-4 inline-block align-middle"/> <span class="inline-block align-middle">{{ $t('dashboard.add')}}</span></button>
          <button class="hover:text-primary focus:outline-none justify-self-end inline-block align-middle ml-3" type="button" @click="hideDrawer" aria-label="Close drawer">
            <icon-x class="h-8"/>
          </button>
        </div>
      </div>
      <div class="mb-4 pr-8">
        <upload-drag-and-drop :value="media" @input="inputDrag"/>
      </div>
      <input-normal class="mb-4 pr-8" v-model="keyword" inputtype="text" inputname="search" inputid="search" :inputplaceholder="$t('dashboard.search_pic')">
        <template #title>{{ $t('dashboard.search_unsplash') }}</template>
        <template #icon>
            <icon-search v-if="keyword===''" class="w-5 h-5 absolute top-1/2 transform right-4 -translate-y-1/2 text-black-400 hover:text-primary"/>
            <span @click="keyword=''" v-if="keyword!==''" class="cursor-pointer w-5 h-5 absolute top-1/2 transform right-4 -translate-y-1/2 text-black-400">
            <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
            </svg>
        </span>
        </template>
      </input-normal>
      <div v-if="Object.keys(searchResults).length > 0" class="search-results-box">
        <div class="grid grid-cols-2 gap-6 pr-8">
            <div v-for="image of searchResults.data.response.results" :key="image.id" class="relative">
                <div v-if="media.uuid === image.id" class="absolute -top-1 -right-2 z-20 text-red">
                    <svg xmlns="http://www.w3.org/2000/svg" class="h-8 w-8" viewBox="0 0 20 20" fill="currentColor">
                    <path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clip-rule="evenodd" />
                    </svg>
                </div>
                <div class="rounded-lg w-full h-full overflow-hidden relative cursor-pointer">
                    <picture-unsplash :image="image" :fullResolution="fullResolution" @input="inputUnsplash"/>
                </div>
            </div>
        </div>
        <infinite-loading @infinite="loadImages"></infinite-loading>
      </div>
      <div v-if="sceleton" class="search-results-box">
        <div class="grid grid-cols-2 gap-6 pr-8">
            <div v-for="image of imagesSceleton" :key="image.id" class="relative">
                <div class="rounded-lg w-full h-full overflow-hidden relative cursor-pointer">
                    <img class="w-full h-full animate-slow-pulse" :src="image.url" />
                </div>
            </div>
        </div>
      </div>
      <div class="footer fixed bottom-0 bg-white w-full">
        <div class="grid grid-cols-3 justify-items-center content-center">
          <span class="mt-2 cursor-pointer hover:text-primary">
                <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M11 19l-7-7 7-7m8 14l-7-7 7-7" />
                </svg>
          </span>
          <span class="mt-2">{{page}}<span v-if="Object.keys(searchResults).length > 0"></span></span>
          <span  class="mt-2 cursor-pointer hover:text-primary">
                <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M13 5l7 7-7 7M5 5l7 7-7 7" />
                </svg>
          </span>
        </div>
      </div>
    </div>
    <div @click="hideDrawer" :class="[drawerVisible ? 'block' : 'hidden', modal ? 'webheight webwidth rounded-lg' : 'w-full rounded-r-none']" class="absolute top-0 left-0 w-screen opacity-30 z-10 h-screen bg-black"></div>
  </div>
</template>

<script>
import IconPlus from '../icons/IconPlus.vue'
import IconX from '../icons/IconX.vue'
import IconSearch from '../icons/IconSearch.vue'
import InputNormal from '../ui/InputNormal.vue'
import UploadDragAndDrop from '@/components/ui/UploadDragAndDrop.vue'
import { functions } from '@/firebase'
import PictureUnsplash from '@/components/dashboard/PictureUnsplash.vue'
import { Media } from 'delivery-boosting-2021-model'
import InfiniteLoading from 'vue-infinite-loading'

export default {
    components: { IconPlus, IconX, IconSearch, UploadDragAndDrop, PictureUnsplash, InputNormal, InfiniteLoading },
    name: 'SearchUnsplash',
    data () {
        return {
            keyword: '',
            searchResults: {},
            searchTimeout: undefined,
            page: 0,
            media: {},
            sceleton: false,
            imagesSceleton: [
                {
                    id: 1,
                    url: 'https://images.unsplash.com/photo-1612538498456-e861df91d4d0?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=967&q=80'
                },
                {
                    id: 2,
                    url: 'https://images.unsplash.com/photo-1612538498456-e861df91d4d0?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=967&q=80'
                },
                {
                    id: 3,
                    url: 'https://images.unsplash.com/photo-1612538498456-e861df91d4d0?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=967&q=80'
                },
                {
                    id: 4,
                    url: 'https://images.unsplash.com/photo-1612538498456-e861df91d4d0?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=967&q=80'
                },
                {
                    id: 5,
                    url: 'https://images.unsplash.com/photo-1612538498456-e861df91d4d0?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=967&q=80'
                },
                {
                    id: 6,
                    url: 'https://images.unsplash.com/photo-1612538498456-e861df91d4d0?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=967&q=80'
                },
                {
                    id: 7,
                    url: 'https://images.unsplash.com/photo-1612538498456-e861df91d4d0?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=967&q=80'
                },
                {
                    id: 8,
                    url: 'https://images.unsplash.com/photo-1612538498456-e861df91d4d0?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=967&q=80'
                },
                {
                    id: 9,
                    url: 'https://images.unsplash.com/photo-1612538498456-e861df91d4d0?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=967&q=80'
                },
                {
                    id: 10,
                    url: 'https://images.unsplash.com/photo-1612538498456-e861df91d4d0?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=967&q=80'
                }
            ]
        }
    },
    watch: {
        keyword: {
            immediate: true,
            async handler () {
                if (this.searchTimeout) {
                    clearTimeout(this.searchTimeout)
                }
                this.searchTimeout = setTimeout(
                    this.searchMedia,
                    350
                )
            }
        },
        drawerVisible: {
            immediate: true,
            async handler () {
                if (typeof this.title !== 'undefined' && this.title !== '' && this.drawerVisible) {
                    this.keyword = this.title
                }
            }
        },
        value: {
            immediate: true,
            handler () {
                this.media = this.value
            }
        }
    },
    created () {
        const onEscape = (e) => {
            if (this.drawerVisible && e.keyCode === 27) {
                this.hideDrawer()
            }
        }
        document.addEventListener('keydown', onEscape)
        this.$once('hook:destroyed', () => {
            document.removeEventListener('keydown', onEscape)
        })
    },
    computed: {
        restaurant () {
            return this.$store.state.restaurant
        }
    },
    methods: {
        hideDrawer () {
            this.$emit('hide')
        },
        async searchMedia () {
            if (this.drawerVisible) {
                this.sceleton = true
                this.searchResults = {}
                this.page = 1
                var unsplashSearch = functions.httpsCallable('unsplashSearch')
                const res = await unsplashSearch({
                    keyword: this.keyword
                })
                this.sceleton = false
                this.searchResults = res
            }
        },
        addAndSave () {
            this.$emit('input', this.media)
            this.$emit('hide')
        },
        inputDrag (i) {
            // console.log(this.media)
            this.media = i
        },
        inputUnsplash (i) {
            this.media = i
            // this.value = this.media
        },
        async loadImages ($state) {
            if (this.drawerVisible && this.keyword !== '') {
                this.page += 1
                var length = Object.keys(this.searchResults.data.response.results).length
                var unsplashSearch = functions.httpsCallable('unsplashSearch')
                const temp = await unsplashSearch({
                    keyword: this.keyword,
                    page: this.page
                })
                for (const pic of temp.data.response.results) {
                    this.searchResults.data.response.results[length] = pic
                    length += 1
                }
                $state.loaded()
            }
        }
    },
    props: {
        value: {
            Type: Media,
            default () {
                return {}
            }
        },
        object: {
            Type: Object
        },
        drawerVisible: {
            Type: Boolean
        },
        fullResolution: {
            Type: Boolean
        },
        modal: {
            Type: Boolean
        },
        title: {
            Type: String
        }
    }
}
</script>

<style scoped>
    .animate-slow-pulse {
        animation: pulse 1s cubic-bezier(0.4, 0, 0.6, 1) infinite;
    }
  .search-results-box {
        overflow-y: scroll;
        height: -webkit-fill-available;
        padding-bottom: 10px;
    }
  .footer {
    height: 0px;
  }
  .webheight{
      height: -webkit-fill-available !important
  }
  .webwidth{
      width: -webkit-fill-available !important
  }
</style>
