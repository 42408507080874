<template>
    <div>
        <label class="block text-sm font-bold">
            <slot name="title"/>
        </label>
        <div class="mt-1 border border-gray-200 border-dashed cursor-pointer rounded-md px-6 pt-5 pb-6 flex justify-center relative overflow-hidden">
            <div class="absolute h-full w-full top-0 left-0 bg-gray-600 z-0" v-if="media.url && media.url != ''" :src="media.url"></div>
            <img class="absolute h-full w-full object-cover top-0 left-0 z-10 opacity-70" v-if="media.url && media.url != ''" :src="media.url">
            <label :for="uuid" class="cursor-pointer block space-y-1 text-center z-10" :class="{'text-white': media.url && media.url != ''}">
                <svg xmlns="http://www.w3.org/2000/svg" class="mt-2 cursor-pointer mx-auto h-12 w-12" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z" />
                </svg>
                <div class="cursor-pointer opacity-100 flex text-sm justify-center">
                    <p class="pr-1 cursor-pointer">{{$t('dashboard.search_new_pics')}}</p>
                    <p v-if="media.origin === 'Unsplash'" class="opacity-80 text-white text-xxs z-10 transform transition-transform">
                    </p>
                </div>
                <div v-show="media.origin === 'Unsplash'">
                <p :class="{'text-white': media.url && media.url != '', 'text-gray-500': !media.url}" class="text-xs justify-center">
                    <a >Aktuelle Bildquelle: Unsplash, Author: {{media.author}}</a>
                </p>
                </div>
            </label>
        </div>
    </div>
</template>

<script>
import { v4 } from 'uuid'
import { storage } from '@/firebase'
import { Media } from 'delivery-boosting-2021-model'
import imageCompression from 'browser-image-compression'

export default {
    name: 'PicturePlaceholder',
    props: {
        value: {
            type: Media,
            default () {
                return new Media()
            }
        }
    },
    data () {
        return {
            uuid: v4(),
            media: {
                url: (this.value || {}).url,
                alt: (this.value || {}).alt,
                type: (this.value || {}).type,
                origin: (this.value || {}).origin || '',
                author: (this.value || {}).author || '',
                authorUrl: (this.value || {}).authorUrl || ''
            }
        }
    },
    watch: {
        value () {
            const value = this.value || {}
            this.media = {
                url: value.url,
                alt: value.alt,
                type: value.type,
                origin: value.origin || '',
                author: value.author || '',
                authorUrl: value.authorUrl || ''
            }
        }
    },
    methods: {
        reset (e) {
            e.preventDefault()
            this.media = {}
            this.$emit('input', new Media(this.media))
        },
        async inputChanged ({ target }) {
            console.log(target)
            const file = target.files[0]
            if (file) {
                const ref = storage.ref().child(`uploads/${this.$route.params.restaurantId}/${v4()}/${file.name}`)
                const img = await imageCompression(file, { maxSizeMB: 0.25 })
                await ref.put(img)
                this.media = {
                    url: await ref.getDownloadURL(),
                    alt: '',
                    type: file.type
                }
                this.$emit('input', new Media(this.media))
            } else {
                this.$emit('input', new Media())
                this.media = {
                    url: '',
                    alt: '',
                    type: ''
                }
            }
        }
    }
}
</script>
